.root {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #4E4F61;
}

.content {
  width: min(100% - 64px, 1120px);
  margin: 57px 32px 32px 32px;
  color: #ffffff;
}

.text {
  position: relative;
  font-size: 23px;
  font-weight: 400;
  padding: 57px 32px 32px 32px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 64px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin-left: 48px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  color: #DBFFFE;
  position: relative;
  margin-bottom: 72px;

  &:after {
    content: '';
    height: 4px;
    background-color: #64B3B0;
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -4px;
  }
}

.shashlik {
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
}

.img {
  margin: auto;
  width: 1100px;
  height: 295px;
  background-image: url('../../assets/items/shashlik_1.svg');
  background-size: 100% 100%;
}

@media screen and (max-width: 1120px) {
  .content {
    width: min(100% - 32px, 1440px);
    margin: 50px 15px 32px 15px;
  }

  .title {
    font-size: 20px;
  }

  .text {
    font-size: 17px;
    margin-bottom: 32px;
    padding: 50px 32px 32px 32px;
  }
}
