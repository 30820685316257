.root {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  height: 474px;
  color: #ffffff;
  background-color: #02233C;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(75, 76, 96, 0.65);
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(2px);
}

.content {
  margin: 0 32px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: min(100%, 1120px);
  height: 100%;
  text-align: center;
}

.header {
  font-size: 45px;
  font-weight: 400;
  line-height: 36px;
  max-width: 659px;
}

.main {
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  max-width: 715px;
}

@media screen and (max-width: 1120px) {
  .root {
    height: 320px;
  }

  .content {
    margin: 0 15px;
  }

  .header {
    font-size: 25px;
    line-height: 28px;
    max-width: 536px;
  }

  .main {
    font-size: 17px;
    line-height: 20px;
    max-width: 600px;
  }
}
