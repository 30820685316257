.root {
  height: 50px;
  display: flex;
  position: absolute;
  left: 0;
  top: -25px;
}

.text {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-color: #475862;
  color: #DBFFFE;
  font-size: 35px;
  font-weight: 700;
  padding: 0 12px;
}

.img {
  width: 34px;
  height: 34px;
  margin: 8px 15px 8px 8px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1120px) {
  .root {
    height: 35px;
    top: -18px;
  }

  .text {
    font-size: 25px;
  }

  .img {
    width: 26px;
    height: 26px;
    margin: 4px 8px 4px 4px;
  }
}