.root {
  position: sticky;
  z-index: 999;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: #2e2f40;
}

.content {
  margin: 0 32px;
  line-height: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(100%, 1120px);
  height: 100%;
}

.logo {
  width: 125px;
  height: 33px;
  background: url('../../assets/icons/logo.svg') no-repeat center;
}

.menu {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;

  * {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .items *, .language, .menu_button {
    transition: color .17s ease;
  }

  .menu_icon {
    transition: background-color .17s ease;
  }
}

.items {
  .active {
    text-decoration: underline #64b3b0 2px;
    text-underline-offset: 6px;
  }

  :hover {
    color: #8fd1cf;
  }

  :active {
    color: #378582;
  }

  .item {
    position: relative;

    &:not(:last-child):after {
      position: absolute;
      right: -20px;
      width: 1px;
      height: 30px;
      content: '';
      pointer-events: none;
      opacity: 0.25;
      background-color: #ffffff;
    }
  }

  :not(:first-child) {
    margin-left: 20px;
  }

  :not(:last-child) {
    margin-right: 20px;
  }
}

.language {
  margin-left: 40px;

  * {
    margin-right: 5px;
  }
}

.ru_icon {
  width: 21px;
  height: 15px;
  background: url('../../assets/icons/ru.svg') no-repeat center;
}

.en_icon {
  width: 21px;
  height: 15px;
  background: url('../../assets/icons/en.svg') no-repeat center;
}

.menu_button {
  display: none;

  &:hover {
    color: #8fd1cf;

    .menu_icon {
      background-color: #8fd1cf;
    }
  }

  &:active {
    color: #378582;

    .menu_icon {
      background-color: #378582;
    }
  }

  * {
    margin-left: 5px;
  }
}

.menu_icon {
  width: 30px;
  height: 24px;
  background-color: #ffffff;
  mask: url('../../assets/icons/menu.svg') no-repeat center;
}

@media screen and (max-width: 1120px) {
  .root {
    height: 55px;
  }

  .content {
    margin: 0 15px 0 15px;
  }

  .language {
    display: none;
  }

  .item {
    padding: 12px 0 12px 15px;

    &:not(:last-child):after {
      display: none;
    }
  }

  .menu .open {
    max-height: 54px * 4;
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.5);
  }

  .items {
    position: absolute;
    z-index: 999;
    top: 55px;
    left: 0;
    display: unset;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    transition: max-height .4s ease, box-shadow .4s ease;
    background-color: #3c3d52;

    :not(:first-child) {
      margin-left: 0;
    }

    :not(:last-child) {
      margin-right: 0;
    }
  }

  .menu_button {
    display: flex;
  }
}
