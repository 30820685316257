.root {
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 215px;
  box-sizing: border-box;
  height: 40px;
  padding: 0 25px 0 25px;
  cursor: pointer;
  transition: background-color .17s ease, border-color .17s ease, color .17s ease;
  white-space: nowrap;
  text-transform: uppercase;
  color: #dbfffe;
  border: 2px solid #64b3b0;
  background-color: rgba(100, 179, 176, 0.2);

  &:hover {
    color: #e5f6f6;
    border-color: #aee2e0;
    background-color: rgba(164, 231, 223, 0.2);
  }

  &:active {
    color: #3ab1ad;
    border-color: #327d7a;
    background-color: rgba(44, 104, 102, 0.2);
  }
}
