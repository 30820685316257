.root {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('../../assets/items/background-map.png');
  background-position: center;
  background-size: cover;
  color: #ffffff;
}

.content {
  margin-top: 57px;
  padding-top: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: min(1120px, 100vw - 36px);
  min-height: 600px;
}

.info {
  box-sizing: border-box;
  width: clamp(420px, 50vw - 18px, 553px);
  height: 600px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.form {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: clamp(420px, 50vw - 18px, 553px);
  height: 600px;
  padding: 32px;
}

.title {
  font-size: 35px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.input, .textarea {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  color: #373849;
  border: none;
  border-radius: 5px;
  outline: none;
  background: #ffffff;

  &:disabled {
    background-color: #dcdcdc;
    cursor: not-allowed;

    &::placeholder {
      color: #373849;
    }
  }
}

.textarea {
  height: 174px;
  resize: none;
}

.or {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: #64b3b0;
}

.map {
  width: 100%;
  height: 314px;
  background: url('../../assets/items/map.png') center no-repeat;
  background-size: cover;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.address {
  width: 100%;
  height: 40px;
  background-color: #373849;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.card_root {
  width: 100%;
  height: 158px;
  border-radius: 10px;
  background: rgba(81, 81, 94, 0.75);
  box-shadow: 0 10px 15px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.card_content_title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.card_title {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 239px;
  height: 42px;
  color: #DBFFFE;
  border-radius: 6px;
  background-color: #4F5F6A;
}

.card_content {
  display: flex;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  font-size: 20px;

  a {
    text-decoration: none;
    color: #ffffff;
  }
}

@media screen and (max-width: 1120px) {
  .content {
    margin: 50px 15px 0 15px;
    padding-top: 17px;
  }

  .info, .form {
    padding: 15px;
  }
}

@media screen and (max-width: 875px) {
  .info {
    justify-content: space-around;
    width: 470px;
    height: 540px;
    padding-bottom: 0;
  }

  .form {
    padding-top: 0;
    width: 470px;
    height: 540px;
  }
}

@media screen and (max-width: 589px) {
  .info {
    height: 380px;
  }

  .map {
    height: 205px;
  }

  .address {
    height: 28px;
    font-size: 13px;
  }

  .card_root {
    height: 101px;
  }

  .card_title {
    height: 28px;
    font-size: 15px;
  }

  .card_content {
    font-size: 17px;
  }
}
