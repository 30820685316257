.root {
  background-color: #44465A;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.content {
  padding-top: 57px;
  position: relative;
  font-size: 23px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1120px;
  margin: 57px 32px 32px 32px;

  b {
    font-weight: 600;
  }
}

@media screen and (max-width: 1120px) {
  .content {
    font-size: 17px;
    line-height: 20px;
    margin: 50px 15px 32px 15px;
    padding-top: 50px;
  }
}

