.root {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 123px;
  background-color: #2c2d3d;
  color: #ffffff;
}

.content {
  margin: 0 32px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: min(100%, 1120px);
  height: 100%;
}

@media screen and (max-width: 1120px) {
  .root {
    height: 77px;
  }

  .content {
    font-size: 13px;
    margin: 0 15px;
  }
}
