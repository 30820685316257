* {
  font-family: 'Uni Sans', sans-serif;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Uni Sans';
  src: url('./assets/fonts/uni-sans-regular.woff') format('woff'),
  url('./assets/fonts/uni-sans-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Sans';
  src: url('./assets/fonts/uni-sans-bold.woff') format('woff'),
  url('./assets/fonts/uni-sans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Uni Sans';
  src: url('./assets/fonts/uni-sans-semi-bold.woff') format('woff'),
  url('./assets/fonts/uni-sans-semi-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
