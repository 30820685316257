.root {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #5B5C6C;
}

.content {
  width: 1120px;
  height: 300px;
  margin: 57px 32px 32px 32px;
  padding-top: 57px;
  background-size: auto 300px;
  background-image: url('../../assets/items/clients.svg');
  background-position: bottom;
  background-repeat: repeat-x;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 300px;
    background: linear-gradient(90deg, #5B5C6C 37.39%, rgba(91, 92, 108, 0) 98.77%);
  }

  &:after {
    right: 0;
    content: '';
    position: absolute;
    width: 100px;
    height: 300px;
    background: linear-gradient(270deg, #5B5C6C 37.39%, rgba(91, 92, 108, 0) 98.77%);
  }
}

@media screen and (max-width: 1120px) {
  .content {
    height: 224px;
    margin: 50px 15px 32px 15px;
    padding-top: 50px;
    background-size: auto 224px;

    &:before, &:after {
      height: 224px;
    }
  }
}
